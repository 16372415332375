<template>
  <div class="gridproductos">
    <v-container>
      <v-card>
        <v-card-title primary-title>
          <v-icon left large>mdi-shoe-heel</v-icon>
          <span class="headline">Productos</span>
          <v-spacer></v-spacer>
  
        <DialogEspera :dialogEspera="dialogEspera"></DialogEspera>

          <v-dialog v-model="dialogEliminar" persistent max-width="290">
            <v-card>
              <v-card-title class="title">¿Eliminar producto?</v-card-title>
              <v-card-text>{{ itemEliminar.nombre }}</v-card-text>
              <v-card-actions>
                <v-btn color="error" depressed @click="borrarItem">Si</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="info" depressed @click="dialogEliminar = false; itemEliminar = Object.assign({}, defaultProducto)">No</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          
          <v-dialog v-model="dialogEditar" scrollable max-width="800px">
            <template v-slot:activator="{ on }">
                <v-btn color="primary" dark v-on="on" depressed @click="nuevoItem">
                  <v-icon left>mdi-plus</v-icon> Nuevo</v-btn>
            </template>
            
            <v-card>
                <v-card-title>
                  <span class="title">{{ formTitle }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="7">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                    dense
                                    v-model="editedProducto.nombre"
                                    name="txtNombre"
                                    label="Nombre*"
                                    required
                                    persistent-hint></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-text-field 
                                    dense
                                    v-model="editedProducto.corridas"
                                    label="Corridas*" 
                                    hint="escribe las corridas separas por espacio"
                                    persistent-hint
                                    required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                    dense
                                    v-model="editedProducto.precios"
                                    label="Precios*"
                                    hint="escribe precios separados por espacio (mismo orden corridas)"
                                    persistent-hint
                                    required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <v-select 
                                  dense
                                  v-model="editedProducto.tipocalzado"
                                  :items="arrTipocalzado"
                                  label="Tipo de calzado*"
                                  required
                                  hint="selecciona el tipo de calzado"
                                  persistent-hint
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  dense
                                  v-model="editedProducto.claveproducto"
                                  label="Código"
                                  hint="escribe el código del zapato"
                                  persistent-hint
                                  ></v-text-field>

                              </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="5">
                            <v-card>
                                <v-file-input name="foto1" accept="image/*" label="Selecciona una foto" @click:clear="onClear" @change="onChangeFile" prepend-icon="mdi-camera"></v-file-input>
                                <v-card-text>
                                    <v-img id="laimagen" :src="editedProducto.foto1_base64" height="200" filled contain accept="image/png,image/gif,image/jpeg"></v-img>
                                </v-card-text>
                                
                            </v-card>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                      <v-col><small>* campos obligatorios</small></v-col>
                    </v-row>
                    
                </v-card-text>
                
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn class="error" dark @click="cerrarDialog" depressed>Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="success" dark @click="grabar" depressed>
                        <v-icon left>mdi-checkbox-marked-circle</v-icon>
                        <span>Grabar</span>
                    </v-btn>
                </v-card-actions>
            </v-card>

          </v-dialog>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar por nombre (escribe mas de 2 letras)"
              single-line
              hide-details
            ></v-text-field>
            <v-data-table
                :headers="headers"
                :items="productos"
                :loading="isLoading"
                :options.sync="options"
                :search="search"
                :server-items-length="totalProductos"
                :items-per-page="5"
                item-key="id"
                no-data-text="No se encontraron productos"
                loading-text="Buscando productos"
                :must-sort="true"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-arrow-left',
                  nextIcon: 'mdi-arrow-right',
                  'items-per-page-options': [10,25,50]
                }"
              >
                <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editarItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      @click="confirmaBorrar(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
              </v-data-table>  
        </v-card-text>
      </v-card>
    </v-container>
    <SnackbarMensajes v-bind:sb="sb"></SnackbarMensajes>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService.js'
import SnackbarMensajes from '@/components/SnackbarMensajes.vue'
import DialogEspera from '@/components/DialogEspera.vue'

export default {
  name: 'GridProductos',
  components: { SnackbarMensajes, DialogEspera },
  data: () => ({
    sb: {
      mostrar: false,
      mensaje: '',
      color: 'success',
      timeout: 3000,
      multiline: false,
    },
    dialogEspera: false,
    dialogEditar: false,
    dialogEliminar: false,
    isLoading: true,
    productos: [],
    totalProductos: 0,
    editando: false,
    search: '',
    arrTipocalzado: [
      { text: "Huarache Niña", value: "HUNA" },
      { text: "Huarache Dama", value: "HUDA" },
      { text: "Huarache Niño", value: "HUNO" },
      { text: "Valerina Dama", value: "VADA" },
      { text: "Valerina Niña", value: "VANI" },
      { text: "Teni Juvenil", value: "TEJU" },
      { text: "Teni Caballero", value: "TECA" },
      { text: "Teni Niño", value: "TENI" },
      { text: "Bota Rodeo Niño", value: "BORNI" },
      { text: "Bota Rodeo Dama", value: "BORDA" },
      { text: "Bota Rodeo Caballero", value: "BORCA" },

      { text: "Zapato Caballero", value: "ZACA" },
      { text: "Zapato Niño", value: "ZPNH" },
    ],
    itemEliminar: {
      id: -1,
      nombre: '',
      corridas: '',
      precios: '',
      foto1_base64: '',
      foto1_filename: '',
      tipocalzado: '',
      claveproducto: '',
    },
    defaultProducto: {
      id: -1,
      nombre: '',
      corridas: '',
      precios: '',
      foto1_base64: '',
      foto1_filename: '',
      tipocalzado: '',
      claveproducto: '',
    },
    editedProducto: {
      id: -1,
      nombre: '',
      corridas: '',
      precios: '',
      foto1_base64: '',
      foto1_filename: '',
      tipocalzado: '',
      claveproducto: '',
    },
    options: {
      sortBy: ['id'],
      sortDesc: [true]
    },
    headers: [
        { text: 'Id', value: 'id', sortable: true },
        {
          text: 'Nombre',
          align: 'start',
          sortable: true,
          value: 'nombre',
        },
        { text: 'Corridas', value: 'corridas', sortable: false },
        { text: 'Precios', value: 'precios', sortable: false },
        { text: 'Opciones', value: 'actions', sortable: false },
      ],
    valid: true,
  }),
  computed: {
    formTitle () {
      return this.editando ? 'Editar producto' : 'Nuevo producto'
    },
  },
  watch: {
    options: {
      handler(){
        // console.log("Cambiaron opciones ");
        // console.log(this.options);
        this.getDataFromApi();
      }
    },
    search: {
      handler() {
        if(this.search.length > 2 || this.search.length == 0){
          //console.log("Filtrando");
          this.getDataFromApi();
        }
      }
    }
  },
  methods: {
    nuevoItem(){
      this.editando = false;
      this.editedProducto = Object.assign({}, this.defaultProducto);
      this.dialogEditar = true;
    },
    editarItem (item) {
      this.dialogEspera = true;
      this.editando = true
      this.editedProducto = Object.assign({}, item)
      this.dialogEditar = true
      ApiService.getImagenProducto(this.$store.getters.token,item.id)
          .then(response => {
              // 200 => Encontrado el producto
              
              if(response.status == 200){
                this.editedProducto.foto1_base64 = response.data.foto1_base64
              }else{
                this.showMensaje(`Error al obtener imagen de producto ${response.status} - ${response.statusText} `,'error',true)
              }
          })
          .catch(error => {
            this.showMensaje(`Error al obtener imagen producto ${error.response.status} - ${error.response.statusText}`,'error',true)
          })
          .finally(()=> {
            this.dialogEspera = false;
          })

    },

    confirmaBorrar (item) {
      this.editando = false;
      this.itemEliminar = Object.assign({}, item);
      this.dialogEliminar = true;
    },

    borrarItem(){
      this.dialogEspera = true;
      ApiService.borrarProducto(this.$store.getters.token,this.itemEliminar.id)
          .then(response => {
              // 204 => No content
              
              if(response.status == 204){
                this.getDataFromApi();
                
                this.showMensaje(`Producto eliminado`,'info',false)
                this.dialogEliminar = false;
                this.itemEliminar = Object.assign({}, this.defaultProducto);
              }else{
                  this.showMensaje(`Error al eliminar producto ${response.status} - ${response.statusText} `,'error',true)
              }
          })
          .catch(error => {
            this.showMensaje(`Error al borrar producto ${error.response.status} - ${error.response.statusText}`,'error',true)
          })
          .finally(()=> {
            this.dialogEspera = false;
          })
    },

    cerrarDialog () {
      this.dialogEditar = false
      this.editando = false;
      this.editedProducto = Object.assign({}, this.defaultProducto);
    },

    showMensaje(mensaje,color,multiline) {
      this.sb.mensaje = mensaje
      this.sb.multiline = multiline
      this.sb.color = color
      this.sb.mostrar = true
    },

    grabar: function(){
      const data = {
          id: this.editedProducto.id,
          nombre: this.editedProducto.nombre,
          corridas: this.editedProducto.corridas,
          precios: this.editedProducto.precios,
          tipocalzado: this.editedProducto.tipocalzado,
          claveproducto: this.editedProducto.claveproducto,
          foto1_base64: this.editedProducto.foto1_base64,
          foto1_filename: this.editedProducto.foto1_filename
      }

      this.dialogEspera = true;
      ApiService.grabaProducto(this.$store.getters.token,data)
          .then(response => {
              // 201 => Created :: 200 => OK Updated
              const checkStatus = this.editedProducto.id == -1 ? 201 : 200;
              if(response.status == checkStatus){
                this.getDataFromApi();
                this.showMensaje(`Producto grabado ID: ${response.data.id}`,'success',false)
                this.editando = false;
                this.dialogEditar = false;
                this.editedProducto = Object.assign({}, this.defaultProducto)
              }else{
                  this.showMensaje(`Error al grabar producto ${response.status} - ${response.statusText}`,'error',true)
              }
          })
          .catch(error => {
            this.showMensaje(`Error al grabar producto ${error.response.status} - ${error.response.statusText}`,'error',true)
          })
          .finally(() => {
            this.dialogEspera = false
          })
    },
    onChangeFile: function(selectedFile) {
        //const file = document.querySelector('input[type=file]').files[0];
        const file = selectedFile;
        if(file){
            this.filename = file.name;
            const reader = new FileReader();
            
            reader.onload = e => {
                console.log("Cargando foto");
                this.editedProducto.foto1_base64 = e.target.result.toString();
            };
            
            reader.onerror = error => {
              console.log(error)
              this.showMensaje('Error al leer imagen','error',false)
            };

            reader.readAsDataURL(file);

        }
    },
    onClear: function() {
        this.editedProducto.foto1_base64 = "";
    },
    getDataFromApi(){
      this.isLoading = true
      //console.log(this.options);
      ApiService.getProductos(this.$store.getters.token,this.options,this.search)
        .then(response => {
          if(response.status == 200){
            this.productos = response.data;
            //console.log("Total: "+response.headers['x-pagination-total-count']);
            this.totalProductos = parseInt(response.headers['x-pagination-total-count']);
            this.isLoading = false;
          }else{
            this.showMensaje(`Error al obtener productos ${response.status} - ${response.statusText} `,'error',true)
          }
        })
        .catch(error => {
          this.showMensaje(`Error al obtener productos ${error.response.status} - ${error.response.statusText}`,'error',true)
          this.isLoading = false
        })
        .finally(() => (this.isLoading = false))
    }
  },
  mounted() {
    //this.getDataFromApi()
  },
}
</script>
